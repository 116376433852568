import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { registerLocaleData } from '@angular/common';

import localeNl from './app/nl';
import { appConfig } from './app/app.config';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeNl, 'nl');

bootstrapApplication(AppComponent, { ...appConfig, providers: [...appConfig.providers, { provide: 'BASE_URL', useFactory: getBaseUrl }] });
