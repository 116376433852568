import { provideHttpClient, withInterceptorsFromDi, withFetch } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideRouter, RouteReuseStrategy, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { CookieConfig, ProvideCookiesNgrx } from '@teamfoster/sdk/cookie-ngrx';
import { ProvideDictionaryNgrx } from '@teamfoster/sdk/dictionary-ngrx';
import { ProvideIcons } from '@teamfoster/sdk/icon';
import { ImageSharpConfig, ProvideOptimisedImageProcessor } from '@teamfoster/sdk/image';
import { ProvideMenuNgrx } from '@teamfoster/sdk/menu-ngrx';
import { environment } from 'src/environments/environment';
import { routes } from './app.routes';
import { reducers, metaReducers, appEffects, CustomSerializer } from './store';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ProvideSocialsNgrx } from './social-media/socials.providers';
import { ProvideAlertBannersNgrx } from './alert-banner/alert-banner.providers';
import { DynamicFormConfig, DynamicFormModule } from '@teamfoster/dynamic-forms';
import { AppRouteReuseStrategy } from './app.route-reuse-strategy';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { provideImgixLoader } from '@angular/common';

const cookieConfig: CookieConfig = {
  analyticsCode: 'G-01B90RJ9NX',
  strippedContentPlaceholder: 'Deze inhoud wordt niet getoond omdat je geen marketing cookies hebt geaccepteerd',
  buttonSettings: 'Instellingen',
  buttonAcceptAll: 'Cookies accepteren',
  buttonClose: 'Sluiten',
  strippedContentButton: 'Cookie-instellingen wijzigen',
  types: {
    analytical: true,
    functional: true,
    marketing: true,
  },
  showSettings: true,
};

export const dynamicFormConfig: DynamicFormConfig = {
  genericInputErrorMessage: 'Het formulier is niet correct ingevuld, controleer de velden en probeer nogmaals.',
  genericServerErrorMessage:
    'Er is iets fout gegaan bij het versturen van het formulier, probeer het later nogmaals. Blijft het probleem voordoen neem dan contact met ons op.',
  //formControls: formControls,
  formControlErrors: {
    pattern: 'Telefoonnummer-error',
    passwordInvalid: 'password-invalid-error',
    // VatNumberInvalid: 'test',
  },
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'disabled' })
    ),
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    provideStore(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    provideEffects(appEffects),
    provideRouterStore({ serializer: CustomSerializer }),
    provideStoreDevtools({ maxAge: 25, logOnly: environment.production }),
    ProvideDictionaryNgrx({}),
    ProvideMenuNgrx({}),
    ProvideSocialsNgrx({}),
    ProvideAlertBannersNgrx(),
    importProvidersFrom(
      // Foster Modules
      DynamicFormModule.forRoot(dynamicFormConfig)
    ),
    ProvideIcons({ spritesheetUrl: 'assets/dist/svg/symbol/svg/sprite.symbol.svg' }),
    ProvideCookiesNgrx(cookieConfig),
    ProvideOptimisedImageProcessor(ImageSharpConfig, {
      placeholderUrl: 'https://eurocross.blob.core.windows.net/media/placeholder-pattern.svg',
      defaultFormat: 'webp',
    }),
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    { provide: LOCALE_ID, useValue: 'nl' },
    // { provide: ErrorHandler, useClass: environment.production ? ApplicationinsightsAngularpluginErrorService : ErrorHandler },
  ],
};
