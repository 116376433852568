import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/alerts.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { AlertService } from '../../services';

export const LoadAlerts$ = createEffect(
  (actions$ = inject(Actions), service = inject(AlertService)) => {
    return actions$.pipe(
      ofType(actions.LoadAlerts),
      switchMap(() =>
        service.getAlerts().pipe(
          map(Alert => actions.LoadAlertsSuccess({ Alert })),
          catchError(error => of(actions.LoadAlertsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);
