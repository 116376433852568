<article
  class="alert-banner"
  role="alert"
  [ngClass]="'text-bg-' + alert().cssClass"
  (click)="bannerClick()"
  [class.cursor-pointer]="hasOneLink() === true"
>
  <div class="position-relative z-2 alert-banner__inner">
    @if (alert().text; as text) {
      <span class="d-inline-block ms-2 fw-semi alert-banner__text">
        {{ text }}
      </span>
    }

    @if (primaryButton() || secondaryButton()) {
      <div class="d-flex gap-2 flex-wrap flex-sm-shrink-0">
        @if (primaryButton()) {
          <fstr-link
            cssClasses="button button--light-{{ alert().cssClass }} button--xs"
            [button]="primaryButton()?.button!"
            [fragment]="primaryButton()?.fragment"
            [showText]="false"
          >
            @if (alert().primaryButton?.icon) {
              <fstr-icon [anchor]="alert().primaryButton?.icon!"></fstr-icon>
            }
            <span>{{ alert().primaryButton?.text || 'Lees meer' }}</span>
          </fstr-link>
        }
        @if (secondaryButton()) {
          <fstr-link
            cssClasses="button button-outline button--light button--xs"
            [button]="secondaryButton()?.button!"
            [fragment]="secondaryButton()?.fragment"
            [showText]="false"
          >
            @if (alert().secondaryButton?.icon) {
              <fstr-icon [anchor]="alert().secondaryButton?.icon!"></fstr-icon>
            }
            <span>{{ alert().secondaryButton?.text || 'Lees meer' }}</span>
          </fstr-link>
        }
      </div>
    }
  </div>
</article>
