import { Routes } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuGuard } from '@teamfoster/sdk/menu-ngrx';
import { NotFoundComponent } from './404.component';
import { SocialChannelsGuard } from './social-media/guards';
import { AlertsGuard } from './alert-banner/guards';

const appGuards = [CookieSettingsGuard, DictionaryGuard, MenuGuard, SocialChannelsGuard, AlertsGuard];

export const routes: Routes = [
  {
    path: 'styleguide',
    loadChildren: () => import('./styleguide/styleguide.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: 'contentblokken',
    pathMatch: 'prefix',
    loadChildren: () => import('./content-blocks/content-blocks.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: 'nieuws',
    loadChildren: () => import('./news/news.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: 'diensten',
    loadChildren: () => import('./services/services.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: 'verhalen',
    loadChildren: () => import('./stories/stories.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: 'pagina',
    loadChildren: () => import('./content/content.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: 'locatie',
    loadChildren: () => import('./location-service/location-service.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...appGuards],
    pathMatch: 'full',
  },
  {
    path: '',
    pathMatch: 'prefix',
    loadChildren: () => import('./landing-pages/landing-pages.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];
