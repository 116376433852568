import {
  Component,
  inject,
  input,
  output,
  ElementRef,
  afterNextRender,
  ViewChild,
  computed,
  signal,
  Renderer2,
  HostListener,
  AfterViewInit,
} from '@angular/core';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { debounceTime, filter } from 'rxjs/operators';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { toSignal } from '@angular/core/rxjs-interop';
import { DOCUMENT, JsonPipe } from '@angular/common';
import { LinkComponent } from '@teamfoster/sdk/button';
import { IconComponent } from '@teamfoster/sdk/icon';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { Subject, Subscription } from 'rxjs';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { ImageComponent } from '@teamfoster/sdk/image';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  standalone: true,
  styleUrls: ['./topbar.component.scss'],
  imports: [
    RouterModule,
    LinkComponent,
    JsonPipe,
    IconComponent,
    InViewModule,
    DictionaryNgrxModule,
    fromMenu.OfMenuTypePipe,
    ImageComponent,
  ],
})
export class TopbarComponent implements AfterViewInit {
  private router = inject(Router);
  private document = inject(DOCUMENT);
  private resizeSubject = new Subject<Event>();
  private subscription: Subscription;

  @ViewChild('topbar') topbar?: ElementRef;
  @ViewChild('topUpperbar') upperBar?: ElementRef;

  private renderer = inject(Renderer2);

  domain = input<string>('');
  collapseOnScroll = input<boolean>(true);
  loading = input<boolean>(false);
  menuActive = input<boolean>(false);
  logoClass = input<string>('');
  menu = input<fromMenu.BaseMenuItem[]>([]);
  upperMenu = input<fromMenu.BaseMenuItem[]>([]);

  fixedNav$ = signal(true);
  activeButton$ = signal<string>('');

  toggleOverlay = output<void>();
  closeOverlay = output<void>();
  openOverlay = output<void>();

  private navigationStart$ = toSignal(this.router.events.pipe(filter(e => e instanceof NavigationStart)));

  // routerEffect$ = effect(() => {
  //   const navigationStart = this.navigationStart$();

  //   if (this.menuActive()) {
  //     this.closeOverlay.emit();
  //   }
  // });

  menuItems$ = computed(() => this.menu().filter(item => !item.type || item.type === 'MenuItem') as fromMenu.MenuItem[]);
  menuHiglight$ = computed(() => this.menuItems$().filter(item => item.featured));
  menuDefault$ = computed(() => this.menuItems$().filter(item => !item.featured));
  isMsc = computed(() => this.domain().includes('msc'));

  constructor() {
    afterNextRender(() => {
      this.calculateHeight();
    });

    this.subscription = this.resizeSubject
      .pipe(
        debounceTime(200) // debounce time in milliseconds
      )
      .subscribe(event => this.calculateHeight());
  }

  ngAfterViewInit(): void {
    // Height bugfix..
    setTimeout(() => {
      this.calculateHeight();
    }, 100);
  }

  calculateHeight() {
    const heightTopbar = this.topbar?.nativeElement?.getBoundingClientRect()?.height;
    const heightUpperTopbar = this.upperBar?.nativeElement?.getBoundingClientRect()?.height;
    // Safe to check `scrollHeight` because this will only run in the browser, not the server.
    this.document.documentElement.style.setProperty('--topbar-height', `${heightTopbar || 0}px`);
    this.document.documentElement.style.setProperty('--topbar-upper-height', `${heightUpperTopbar || 0}px`);
  }

  inview($event: boolean) {
    if (this.collapseOnScroll()) {
      this.fixedNav$.set($event);
    }
  }

  toggleClass(page: fromMenu.MenuItem) {
    const currentActiveButton = this.activeButton$();
    // const button = event.target as Element;
    // // this.renderer.toggleClass(button, 'is--active');

    // button.classList.toggle('is--active');
    if (currentActiveButton === page.guid) {
      this.activeButton$.set('');
    } else {
      this.activeButton$.set(page.guid);
    }
  }

  closeOverlayMenu() {
    this.activeButton$.set('');

    this.closeOverlay.emit();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next(event);
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.closeOverlayMenu();
  }

  translateOptionsPerCountry = [
    {
      country: 'Nederland',
      options: [
        { code: 'nl', title: 'To the dutch website', alt: 'Nederlandse vlag', stagingUrl: 'eurocross.fstr.io', url: 'eurocross.nl' },
        { code: 'en', title: 'Naar de Engelse website', alt: 'Engelse vlag', stagingUrl: 'com.eurocross.fstr.io', url: 'eurocross.com' },
      ],
    },
    {
      country: 'Turkije',
      options: [
        { code: 'en', title: 'Naar Eurocross', alt: 'Engelse vlag', stagingUrl: 'tr-en.eurocross.fstr.io', url: 'TODO' },
        { code: 'nl', title: 'Naar Eurocross', alt: 'Nederlandse vlag', stagingUrl: 'tr-nl.eurocross.fstr.io', url: 'TODO' },
        { code: 'tr', title: 'Naar Eurocross', alt: 'Turkse vlag', stagingUrl: 'eurocross-tr.fstr.io', url: 'TODO' },
      ],
    },
    {
      country: 'Tsjechie',
      options: [
        { code: 'cz', title: 'Naar Eurocross', alt: 'Tsjechische vlag', stagingUrl: 'cz.eurocross.fstr.io', url: 'TODO' },
        { code: 'en', title: 'Naar Eurocross', alt: 'Engelse vlag', stagingUrl: 'cz-en.eurocross.fstr.io', url: 'TODO' },
      ],
    },
  ];

  isStaging = computed(() => this.domain().includes('fstr.io'));

  languageOptions = computed(() => {
    const domain = this.domain();
    const countries = this.translateOptionsPerCountry;

    // get the country from countries by checking of one of the options is in the domain
    const country = countries.find(country => country.options.some(option => domain === option.stagingUrl || domain === option.url));
    const activeOption = country?.options.find(option => option.stagingUrl === domain || option.url === domain);

    if (!country) {
      return [];
    }

    // Filter out the active option from the list
    return country.options.filter(option => option.code !== activeOption?.code);
  });
}
