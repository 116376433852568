import { ChangeDetectionStrategy, Component, Signal, computed, inject, input } from '@angular/core';
import { Alert } from '../../models';
import { JsonPipe, NgClass } from '@angular/common';
import { Button, LinkComponent } from '@teamfoster/sdk/button';
import { Router } from '@angular/router';
import { IconComponent } from '@teamfoster/sdk/icon';

@Component({
  selector: 'app-alert-banner',
  standalone: true,
  imports: [NgClass, LinkComponent, IconComponent, JsonPipe],
  templateUrl: './alert-banner.component.html',
  styleUrl: './alert-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertBannerComponent {
  alert = input.required<Alert>();

  router = inject(Router);

  hasOneLink = computed(() => !!this.alert().primaryButton?.url && !this.alert().secondaryButton?.url);

  primaryButton = computed(() => this.buttonWithFragment(this.alert().primaryButton));
  secondaryButton = computed(() => this.buttonWithFragment(this.alert().secondaryButton));

  buttonWithFragment(button: Button | undefined): { button: Button; fragment: string | undefined } | undefined {
    if (!button || !button.url) {
      return undefined;
    }
    if (!button.routerLink?.join().includes('#')) {
      return {
        button,
        fragment: undefined,
      };
    }

    const urlParts = this.alert().primaryButton?.url!.split('#');
    const urlWithoutAnchor = urlParts?.[0];
    const fragment = urlParts?.[1];

    return {
      button: {
        ...button,
        routerLink: urlWithoutAnchor?.split('/').filter(Boolean),
      },
      fragment,
    };
  }

  bannerClick() {
    if (!this.hasOneLink() || !this.buttonWithFragment(this.alert().primaryButton!)) {
      return;
    }
    const buttonWithFragment = this.buttonWithFragment(this.alert().primaryButton!)!;
    const routerOptions = buttonWithFragment.fragment ? { fragment: buttonWithFragment.fragment } : undefined;
    this.router.navigate(buttonWithFragment.button.routerLink || [buttonWithFragment.button.url], routerOptions);
  }
}
