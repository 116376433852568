import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import * as fromStore from './store';

export function ProvideAlertBannersNgrx(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    provideEffects(fromStore.effects),
  ]);
}
